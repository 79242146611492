<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="get_custom_data_groups"
      item-key="id"
      class="elevation-1"
      :footer-props="{
        itemsPerPageOptions: [10, 20, 30, 100],
        itemsPerPageText: $t('modules.dialog.itemsPerPageText'),
        pageText: $t('modules.dialog.pageText'),
      }"
    >
      <template v-slot:item.control="{ item }">
        <v-btn
          icon
          color="green"
          :title="$t('modules.dialog.texts.settings.edit_bot')"
          @click="toggleAddAndEditGroupModal(true, item)"
          :disabled="!userCanSave()"
        >
          <v-icon small>$iconedit</v-icon>
        </v-btn>
        <v-btn
          icon
          color="red"
          :title="$t('modules.dialog.texts.settings.delete_bot')"
          @click="toggleDeleteGroupModal(true, item)"
          :disabled="!userCanSave()"
        >
          <v-icon small>$icondelete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-layout
      class="mt-5"
      justify-center
    >
      <v-btn
        color="primary"
        height="44"
        width="184"
        elevation="0"
        @click="toggleAddAndEditGroupModal(true)"
        :disabled="!userCanSave()"
      >
        <v-icon left size="14">mdi-plus</v-icon>
        {{ $t('modules.dialog.texts.settings.add_group') }}</v-btn>
    </v-layout>

    <template>
      <div class="text-center">
        <v-dialog v-model="addGroupModalState" width="500" scrollable>
          <v-form
            ref="addNewGroupForm"
          >
            <v-card>
              <v-btn
                icon
                absolute
                top
                right
                @click="toggleAddAndEditGroupModal(false)"
              >
                <v-icon size="32" color="black">
                  mdi-close
                </v-icon>
              </v-btn>

              <v-card-title 
                class="font-weight-bold text-h5 pl-7 pt-5"
                v-text="groupId
                  ? $t('modules.dialog.texts.settings.group_parameters')
                  : $t('modules.dialog.texts.settings.add_new_group')"
              ></v-card-title>

              <v-card-text class="px-7 py-2">
                <v-text-field
                  v-model.trim="groupName"
                  :rules="[rules.required]"
                  :label="$t('modules.dialog.texts.settings.name')"
                  outlined
                ></v-text-field>

                <v-text-field
                  v-model.trim="groupPriority"
                  :rules="[rules.onlyDigits]"
                  :label="$t('modules.dialog.texts.settings.group_priority')"
                  outlined
                  type="number"
                ></v-text-field>
              </v-card-text>

              <v-card-actions class="px-7 pt-0 pb-9">
                <v-btn
                  :disabled="!userCanSave()"
                  color="primary"
                  elevation="0"
                  @click="saveOrEditGroup(bot_settings.active_id)"
                  width="100%"
                  height="40"
                  v-text="groupId
                    ? $t('modules.dialog.texts.settings.save')
                    : $t('modules.dialog.texts.settings.add_new_group')" 
                  />
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </div>
    </template>

    <template>
      <v-dialog
        v-model="deleteGroupModalState"
        width="500"
      >
        <v-card>
          <v-btn
            icon
            absolute
            top
            right
            @click="toggleDeleteGroupModal(false)"
          >
            <v-icon size="32" color="black">
              mdi-close
            </v-icon>
          </v-btn>

          <v-card-title class="font-weight-bold text-h5 pl-7 pt-5">
            {{ $t('modules.dialog.texts.settings.group_delete') }}
          </v-card-title>

          <v-card-text>
            <v-sheet
              width="300"
              min-height="100"
              class="text-center ml-auto mr-auto d-flex align-center"
            >
              <div>
                {{ $t(`modules.dialog.texts.settings.sure_to_delete`) }} {{ $t('modules.dialog.texts.settings.group') }}
                <span class="font-weight-bold ml-1">"{{ groupName }}"?</span>
              </div>
            </v-sheet>
          </v-card-text>

          <v-card-actions
            class="px-7 pt-0 pb-9"
          >
            <v-btn
              width="100%"
              height="40"
              color="primary"
              elevation="0"
              @click="deleteGroup()"
            >{{ $t('modules.dialog.texts.settings.delete_group') }}</v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Vue from 'vue';
import i18n from '@/lang/i18n';
import axios from "axios";

export default {
  name: "Groups",

  components: {},

  data () {
    return {
      headers: [
        {
          text: 'Id',
          value: 'id',
          width: 120,
        },
        {
          text: i18n.t('modules.dialog.texts.settings.group_name'), 
          value: 'name',
        },
        {
          text: i18n.t('modules.dialog.texts.settings.group_priority'),
          value: 'priority',
        },
        {
          text: 'Action', 
          value: 'control', 
          sortable: false, 
          width: '150', 
          align: 'center', 
        },
      ],
      addGroupModalState: false,
      deleteGroupModalState: false,
      groupName: null,
      groupPriority: 0,
      groupId: null,
    }
  },

  mounted() {
    this.customDataAxiosGetGroups();
  },

  computed: {
    ...mapGetters([
      'rules',
      'engine_settings',
      'bot_settings',
      'get_custom_data_groups',
      'user',
		]),
  },

  methods: {
    ...mapActions([
      'customDataAxiosGetGroups',
    ]),
    saveOrEditGroup(botId) {
      if (this.$refs.addNewGroupForm.validate()) {
        let url = this.engine_settings.active_url;
        let token = Vue.$cookies.get('token_e');

        axios.post(url + '/kw/custom_modules/create_update_group', { 
          bot_id: botId,
          name: this.groupName,
          priority: parseInt(this.groupPriority) || 0,
          group_id: this.groupId
        }, {
          headers: {
            'X-Csrf-Token': token,
          },
        })
        .then(
          (response) => {
            if(response?.data?.status === 'success') {
              this.toggleAddAndEditGroupModal(false);
              this.customDataAxiosGetGroups();
            }
          },
          (err) => {
            this.$store.dispatch('updateAjaxDialogError', [
              err,
              this.$t('common.error'),
              this.$t(
                'modules.dialog.texts.settings.cant_save_bot'
              ),
            ]);
          }
        );
      }
    },
    deleteGroup() {
      let url = this.engine_settings.active_url;
      let token = Vue.$cookies.get('token_e');

      axios.post(url + '/kw/custom_modules/delete_group', { group_id: this.groupId }, {
        headers: {
          'X-Csrf-Token': token,
        },
      })
      .then(
        (response) => {
          if(response?.data?.status === 'success') {
            this.toggleDeleteGroupModal(false);
            this.customDataAxiosGetGroups();
          }
        },
        (err) => {
          this.$store.dispatch('updateAjaxDialogError', [
            err,
            this.$t('common.error'),
            this.$t(
              'modules.dialog.texts.settings.cant_save_bot'
            ),
          ]);
        }
      );
    },
    toggleAddAndEditGroupModal(state, { id, name, priority } = { id: null, name: null, priority: 0 }) {
      this.groupId = id
      this.groupName = name
      this.groupPriority = parseInt(priority) || 0
      this.addGroupModalState = state
    },
    toggleDeleteGroupModal(state, { id, name } = { id: null, name: null }) {
      this.groupId = id
      this.groupName = name
      this.deleteGroupModalState = state
    },

    userCanSave() {
      const permissions = this.user?.role?.permissions?.custom_data?.sections
      let canSave = true

      if('customdata_groups' in permissions) {
        canSave = permissions['customdata_groups']?.canSave;
      }

      return canSave || !!this.user?.root
    },
  },
};
</script>


<style lang="scss">

</style>
