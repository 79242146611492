<template>
  <v-expansion-panels multiple>
    <template v-for="(f, k) in fields_from_variable">
      <v-expansion-panel
        v-if="checkSomeKeyNotExists(f)"
        :key="k"
      >
        <v-expansion-panel-header
          :color="f.header_color ? f.header_color : ''"
        >
          <v-layout :align-center="true">
            <v-icon
              v-if="f.icon"
              :color="f.icon_color"
              class="mr-2"
            >{{f.icon}}</v-icon>

            {{returnFilterKeyOrLabel(null, f)}}
          </v-layout>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0">
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
              <template
                v-for="(item, key) in f.items"
              >
                <tr
                  v-if="checkKeyNotExists(key, item)"
                  :key="key"
                >
                  <td>
                    <v-btn
                      v-if="item.editable"
                      small
                      icon
                      @click="item.edit_toggle = !item.edit_toggle"
                      color="green"
                      class="mr-2"
                    >
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>

                    <v-icon
                      v-if="item.icon"
                      :color="item.icon_color"
                      class="mr-2"
                    >{{item.icon}}</v-icon>


                    {{ returnLabelFromColumn(key, item) }}
                  </td>
                  <td>
                    <v-layout
                      :align-center="true"
                    >
                      <div
                        class="my-1"
                        v-show="item.edit_toggle"
                      >
                        <template
                          v-if="item.editable"
                        >
                          <!--STRING FIELDS-->
                          <v-text-field
                            v-if="['STRING'].includes(item.type)"
                            outlined
                            dense
                            v-model.trim="item_obj[key]"
                            :readonly="!modal_create_mode && !canSave"
                            hide-details="auto"
                            background-color="white"
                          />

                          <!--TEXTAREA FIELDS-->
                          <v-textarea
                            v-else-if="['TEXT'].includes(item.type)"
                            outlined
                            dense
                            v-model.trim="item_obj[key]"
                            auto-grow
                            rows="2"
                            :readonly="!modal_create_mode && !canSave"
                            hide-details="auto"
                            background-color="white"
                          />

                          <!-- INTEGER FIELDS -->
                          <v-text-field
                            v-else-if="['INTEGER', 'BIGINT'].includes(item.type)"
                            outlined
                            dense
                            v-model.trim="item_obj[key]"
                            :rules="[rules.onlyDigits]"
                            :readonly="!modal_create_mode && !canSave"
                            hide-details="auto"
                            background-color="white"
                          ></v-text-field>

                          <!-- BOOL FIELDS -->
                          <v-checkbox
                            v-else-if="['BOOLEAN'].includes(item.type)"
                            v-model="item_obj[key]"
                            class="mt-0 mb-4"
                            :readonly="!modal_create_mode && !canSave"
                            hide-details="auto"
                          ></v-checkbox>

                        </template>
                      </div>

                      <div
                        v-show="!item.edit_toggle"
                      >
                        {{ item_obj[key] }}
                      </div>
                    </v-layout>

                  </td>
                </tr>
              </template>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </template>
  </v-expansion-panels>

</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'ExpansionPanelVariableField',

  props: {
    modal_create_mode: {
      type: Boolean,
      default: false,
    },
    canSave: {
      type: Boolean,
      default: false,
    },


    field: {
      type: Object,
      default: () => {},
    },
    entity_fields: {
      type: Object,
      default: () => {},
    },
    headers: {
      type: Array,
      default: () => [],
    },
    item_obj: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      expanded: false,
    }
  },

  computed: {
    ...mapGetters([
      'rules',
    ]),

    fields_from_variable() {
      let variable = this.item_obj?.[this.field?.variable]

      if(variable) {
        if(typeof variable === 'string') {
          try {
            variable = JSON.parse(variable)
          } catch (e) {
            console.log(e, 'Error')
          }
          variable = JSON.parse(variable)
        }

        return this.generateOnVariableArray(variable, this.field) || []
      }

      return []
    },
  },

  methods: {

    toggleShow(show) {
      this.$emit('toggle_show', show)
    },

    checkKeyNotExists(key, field) {
      let show = true

      if(
        field?.hide_key_not_exists
        && !(key in this.item_obj)
      ) show = false

      return show
    },
    checkSomeKeyNotExists(f) {
      let show = true

      if(f?.hide_some_key_not_exists) {
        const items = Object.keys(f.items).filter((i) => {
          if(this.checkKeyNotExists(i, f.items[i])) {
            return i
          }
        })

        if(!items?.length) show = false
      }

      return show
    },
    returnLabelFromColumn(key, item = null) {
      let label = this.entity_fields[key]?.label || key
      let label_from_column_name = this.entity_fields?.[item?.label_from_column_name]?.label

      //get label from headers(columns in list)
      if(item?.label_from_column_name) {
        label_from_column_name =  this.headers?.find((i) => i.value === item.label_from_column_name )?.text
      }

      return item?.label || label_from_column_name || label
    },

    returnFilterKeyOrLabel(key, item) {
      const label_from_column_name = this.entity_fields?.[item?.label_from_column_name]?.label

      return item?.label || label_from_column_name || key
    },

    generateOnVariableArray(variable, field) {
      let arr = []
      const findAs = field?.findAs
      if(variable?.length) {
        arr = variable.reduce((acc, curr, index) => {
          if(!curr?.length) return acc

          let regexRange = this.createDynamicRegex(findAs, curr, curr.length === 2);
          let items = Object.keys(this.item_obj)
            .filter(key => regexRange.test(key))
            .reduce((obj, key) => {

              const header = this.headers.find(({name}) => name === key)

              obj[key] = {
                type: header?.type || 'STRING',
                label: header?.text || key
              };
              return obj;
            }, {});

          if(!Object.keys(items)?.length) return acc

          //create Item
          let obj = {
            label: field?.label + ' ' + (index + 1),
            header_color: field?.header_color,
            icon: field?.icon,
            icon_color: field?.icon_color,
            items: items,
          }

          acc.push(obj)
          return acc
        }, [])
      }

      if(!arr?.length) this.toggleShow(false)
      return arr
    },

    /**
     * Создает регулярное выражение на основе префикса, диапазона чисел и типа диапазона.
     * @param {string} var1 - Префикс строки (например, "audit__").
     * @param {number[]} var2 - Массив чисел, представляющий диапазон [start, end].
     * @param {boolean} isRange - Если true, var2 представляет диапазон; если false — конкретные значения.
     * @param {string} [flags] - Дополнительные флаги для RegExp (например, 'i' для нечувствительности к регистру).
     * @returns {RegExp} - Созданное регулярное выражение.
     */
    createDynamicRegex(var1, var2, isRange = false, flags = '') {
      // Экранирование специальных символов в var1
      const escapedVar1 = var1.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

      let pattern;
      if (isRange && var2.length === 2) {
        const start = var2[0];
        const end = var2[1];

        if (start > end) {
          throw new Error('Начальное значение диапазона должно быть меньше или равно конечному.');
        }

        // Проверка, являются ли числа однозначными
        const isSingleDigit = start >= 0 && end < 10;

        if (isSingleDigit) {
          // Однозначные числа: [1-3]
          pattern = `^${escapedVar1}[${start}-${end}]$`;
        } else {
          // Многозначные числа: (start|start+1|...|end)
          const alternatives = [];
          for (let i = start; i <= end; i++) {
            alternatives.push(i.toString());
          }
          pattern = `^${escapedVar1}(?:${alternatives.join('|')})$`;
        }
      } else if (!isRange && var2.length > 0) {
        // Конкретные значения
        const escapedValues = var2.map(num => num.toString().replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
        pattern = `^${escapedVar1}(?:${escapedValues.join('|')})$`;
      } else {
        throw new Error('Некорректные параметры для создания регулярного выражения.');
      }

      return new RegExp(pattern, flags);
    },
  },

}

</script>

<style scoped lang="scss">
::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>