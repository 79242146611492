<template>
  <div class="cd__map" v-if="viewMap">
    <GmapMap
      :center="centerGmapMap"
      :zoom="map_zoom"
      map-type-id="roadmap"
      style="width: 100%; height: 400px"
      :options="{
        scrollwheel: false
      }"
    >
      <template
        v-if="multiple_marker"
      >
        <gmap-info-window
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen=false"
        />

        <gmap-marker
          :key="i"
          v-for="(m,i) in mapArray"
          :position="m.position"
          :clickable="true"
          @click="toggleInfoWindow(m,i)"
        />
      </template>
      <template v-else>
        <GmapMarker
          label="★"
          :position="{
            lat: +mapObject.latitude,
            lng: +mapObject.longitude,
          }"
        />
      </template>


    </GmapMap>
  </div>
</template>

<script>
import * as VueGoogleMaps from 'vue2-google-maps'
import { mapGetters } from 'vuex';

export default  {
  name: 'CdMap',

  props: {
    mapObject: {
      type: Object
    },
    mapArray: {
      type: Array
    },
    multiple_marker: {
      type: Boolean,
      default: false
    },
    map_zoom: {
      type: [Number, String],
      default: 10
    }
  },

  data() {
    return {
      viewMap: false,

      // Markers
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,

      infoOptions: {
        content: '',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
    };
  },

  computed: {
    ...mapGetters([
      'get_instance_settings',
    ]),

    centerGmapMap() {
      if(this.multiple_marker) {
        return this.mapArray?.[0]?.position || {}
      } else {
        return {lat: +this.mapObject.latitude, lng: +this.mapObject.longitude}
      }

    },
  },

  beforeMount () {
    let gmapApiKey = ''
    if (this.get_instance_settings?.length && this.get_instance_settings.find((s) => s.key === 'google_maps_api_key')) {
      gmapApiKey = this.get_instance_settings.find((s) => s.key === 'google_maps_api_key')?.value
    }

    if (typeof google !== 'object') {
      VueGoogleMaps.loadGmapApi({
        key: gmapApiKey,
        libraries: 'places'
      })
    }
  },

  mounted() {
    this.updateMap();
  },

  methods: {
    updateMap() {
      this.viewMap = false
      this.viewMap = true
    },

    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;

      }
    }
  },

  watch: {
    mapObject: {
      handler: 'updateMap',
      deep: true,
    },
    mapArray: {
      handler: 'updateMap',
      deep: true,
    },
  },

}
</script>

<style scoped lang="scss">

</style>
